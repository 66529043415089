import { useFrame } from "react-three-fiber";
import { useRef } from "react";

const LoadingBox = () => {
    const boxRef = useRef();
  
    useFrame(() => {
      boxRef.current.rotation.y += 0.01;
    });
  
    return (
      <mesh ref={boxRef} rotation-x={Math.PI * 0.25} rotation-y={Math.PI * 0.25}>
        <boxBufferGeometry args={[2, 2, 2]} />
        <meshStandardMaterial color={"red"} />
      </mesh>
    );
  };

export default LoadingBox;